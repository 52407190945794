import React from 'react';
import Layout from 'src/components/Layout';
import { Section } from '../components/Section';
import { HeaderTitle, Paragraph, StyledLink } from 'src/components/Typography';
import colors from 'src/style/colors';

const ErrorPage = ({ location }) => {
  return (
    <Layout
      pathname={location.pathname}
      businessType="B2C|B2B"
      pageName="404"
      pageType="page"
      is404
      metaTitle="Le Reacteur - Oops, il y a une erreur"
    >
      <Section
        theme="primary"
        css={{
          marginTop: '12%',
          marginBottom: '12%',
        }}
      >
        <div css={{ display: 'flex', justifyContent: 'center' }}>
          <HeaderTitle
            css={{
              textAlign: 'center',
              width: '70%',
            }}
          >
            La page que vous avez demandée n'existe pas.
          </HeaderTitle>
        </div>

        <Paragraph css={{ textAlign: 'center' }}>
          Vous pouvez revenir à la{' '}
          <StyledLink to="/" css={{ color: colors.yellow }}>
            page d'accueil.
          </StyledLink>
        </Paragraph>
      </Section>
    </Layout>
  );
};

export default ErrorPage;
